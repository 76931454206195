const FALLBACK = "-";

export const toValidNumber = (num) => Number(String(num).replace(/,/g, ""));

export function formatCurrency(num, digits, fallback = FALLBACK) {
  const number = num;

  if (!number) return fallback;

  const fractionDigits =
    typeof digits === "number"
      ? digits
      : Number.isInteger(toValidNumber(number))
        ? 0
        : 2;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits,
  }).format(toValidNumber(number));
}

export const formatNumber = (num) => {
  if (!num) return FALLBACK;
  return toValidNumber(num);
};
